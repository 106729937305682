import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    title: { type: String },
    link: { type: String },
    text: { type: String }

  }

  connect() {
    // console.log("Connected to the share controller");
  }

  share(event) {
    const shareData = {
      title: this.titleValue,
      text: this.textValue,
      url: this.linkValue
    };
    console.log(shareData);
    try {
      navigator.share(shareData);
    } catch (err) {
      console.error("Share error:", err)
      
      alert("Funktion nur für Mobile Geräte verfügbar")
    };
  }

  disconnect() {
  }
}
