import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    issueBulkPlaceholderTarget = document.getElementById('issue_ids')
    issuePrintPlaceholderTarget = document.getElementById('print_issue_ids')
    bulkIssueButton = document.getElementById('bulk_issue_btn')
    bulkPrintButton = document.getElementById('bulk_print_btn')

    connect() {
        super.connect();
    }

    toggle(event) {
        const isChecked = event.currentTarget.checked
        const currentIssue = event.currentTarget.value


        this.toggleIssue(currentIssue, isChecked)


    }

    selectAll() {
        event.preventDefault();

        let issue_elements = document.getElementsByName('issue_bulk_select')
        issue_elements.forEach(el => {
            if (!el.checked) {
                el.checked = true
                this.toggleIssue(el.value, true)
            }
        })
    }

    clearAll() {
        event.preventDefault();

        let issue_elements = document.getElementsByName('issue_bulk_select')
        issue_elements.forEach(el => {
            if (el.checked) {
                el.checked = false
                this.toggleIssue(el.value, false)
            }
        })
    }

    toggleIssue(issue_id, enable) {
        let issue_ids = new Array()

        const issue_ids_split = this.issueBulkPlaceholderTarget.value.split(',')

        issue_ids_split.filter(el => el != '').forEach(el => {
            issue_ids.push(el)
        })

        if (enable) {
            issue_ids.push(issue_id)
        } else {
            issue_ids.pop(issue_id)
        }


        this.issueBulkPlaceholderTarget.value = issue_ids
        this.issuePrintPlaceholderTarget.value = issue_ids
        this.bulkIssueButton.value = issue_ids.length ? `Neues Protokoll (${issue_ids.length})` : 'Neues Protokoll'
        this.bulkPrintButton.value = issue_ids.length ? `Drucken (${issue_ids.length})` : 'Drucken'

        if (issue_ids.length) {
            document.getElementById('issue_select_box').classList.remove('hidden')
        } else {
            document.getElementById('issue_select_box').classList.add('hidden')
        }
    }
}
