import { Controller } from "@hotwired/stimulus"
import { get }        from '@rails/request.js'
import TomSelect from "tom-select"

// Connects to data-controller="ts--select"
export default class extends Controller {
  connect() {

    new TomSelect(
      this.element,
      { 
        create: this.data.get("create"),
        createOnBlur: true,
        openOnFocus: false,
        plugins: ['clear_button'],
        valueField: 'value',
        load: (q, callback) => this.search(q, callback),
        render: {
          option_create: function( data, escape ){
            return '<div class="create"><strong>' + escape(data.input) + '</strong> hinzufügen&hellip;</div>';
          }
        }
      }
    )
  }
  async search(q, callback) {
    console.log(this.data.get("url-value"))
    const response = await get(this.data.get("url-value"), {
      query: { q: q },
      responseKind: 'json'
    })

    if (response.ok) {
      const list = await response.json
      callback(list)
    } else {
      console.log(response)
      callback()
    }
  }

  async change(event) {
    let participant_url = this.data.get("participant-url-value")
    let participant_id = event.target.selectedOptions[0].value

    console.log(participant_url)
    console.log(participant_id)
    console.log("#{participant_url}/#{participant_id}")

    const response = await get(participant_url + "/" + participant_id, {
      responseKind: 'json'
    })
  }
  
}