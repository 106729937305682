import {Controller} from "@hotwired/stimulus"
import {get} from "@rails/request.js"

export default class extends Controller {
    static values = {
        url: String
    }

    connect() {
    }

    change() {
        if (this.element.value) {
            get(`${this.urlValue}?project_id=${this.element.value}`, {
                responseKind: "turbo-stream",
            })
        }
    }

    disconnect() {
    }
}
