import { Controller } from "@hotwired/stimulus";
// import WebViewer from "@pdftron/pdfjs-express";

export default class extends Controller {
  connect() {
    console.log("PDF.js Express has been connected.");

    const pdfUrl = this.data.get("pdfUrl");

    WebViewer(
      {
        path: "/lib/pdfjs-express", // Replace with the actual path to the PDF.js Express library in your project
        initialDoc: pdfUrl,
      },
      this.element
    )
    .then((instance) => {
      const { docViewer, Annotations, Tools } = instance;

      // create a new rectangle tool
      const rectangleTool = new Tools.RectangleCreateTool(docViewer);
      docViewer.setToolMode(rectangleTool);

      // listen for annotation creation
      docViewer.getAnnotationManager().addEventListener('annotationChanged', (annotations, action, { imported }) => {
        if (action === 'add' && !imported) {
          annotations.forEach((annot) => {
            if (annot instanceof Annotations.RectangleAnnotation) {
              // show a popup with a button that calls createIssue when clicked
              const popup = document.createElement('div');
              popup.style.position = 'fixed';
              popup.style.left = '50%';
              popup.style.top = '50%';
              popup.style.transform = 'translate(-50%, -50%)';
              popup.style.backgroundColor = 'white';
              popup.style.padding = '20px';
              popup.style.border = '1px solid black';
              popup.innerHTML = `
                <p>Select an area and click the button below to create an issue.</p>
                <button id="createIssueButton">Aufgabe erstellen</button>
              `;
              document.body.appendChild(popup);

              document.getElementById('createIssueButton').addEventListener('click', () => {
                // get the position of the rectangle
                const x1 = annot.X;
                const y1 = annot.Y;
                const x2 = x1 + annot.Width;
                const y2 = y1 + annot.Height;

                // create an issue with the coordinates of the rectangle
                this.createIssue(`Test (Page: ${annot.PageNumber}, Coordinates: ${x1}, ${y1}, ${x2}, ${y2})`, annot, instance);

                // remove the popup
                document.body.removeChild(popup);
              });
            }
          });
        }
      });

      // load the annotations from the server when the document is fully loaded
      docViewer.addEventListener('documentLoaded', () => {
        fetch(`/protocols/${this.data.get("protocolId")}/issues`, {
          headers: {
            'Accept': 'application/json', // Tell the server we want JSON back
          },
        })
        .then(response => response.json())
        .then(data => {
          const xfdfString = data.map(issue => {
            // create a new rectangle annotation
            const rectAnnot = new Annotations.RectangleAnnotation();
            rectAnnot.PageNumber = issue.protocol_coordinates.page;
            rectAnnot.X = issue.protocol_coordinates.x1;
            rectAnnot.Y = issue.protocol_coordinates.y1;
            rectAnnot.Width = issue.protocol_coordinates.x2 - issue.protocol_coordinates.x1;
            rectAnnot.Height = issue.protocol_coordinates.y2 - issue.protocol_coordinates.y1;

            // create a new FreeText annotation
            const textAnnot = new Annotations.FreeTextAnnotation();
            textAnnot.PageNumber = issue.protocol_coordinates.page;
            textAnnot.X = issue.protocol_coordinates.x2;
            textAnnot.Y = issue.protocol_coordinates.y1;
            textAnnot.setContents(issue.sequential_id.toString());

            // convert the annotations to XFDF
            return instance.annotManager.getAnnotCommand([rectAnnot, textAnnot]);
          }).join('');

          // load the annotations from the XFDF string
          instance.annotManager.importAnnotations(xfdfString);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
      });
    });
  }

  createIssue(description, annot, instance) {
    const protocolId = this.data.get("protocolId");
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    // create an issue with the description
    fetch(`/protocols/${protocolId}/issues`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json', // Tell the server we want JSON back
        'X-CSRF-Token': csrfToken
      },
      body: JSON.stringify({
        description: description,
        project_participant_id: "",
        protocol_coordinates: {
          x1: annot.X,
          y1: annot.Y,
          x2: annot.X + annot.Width,
          y2: annot.Y + annot.Height,
          page: annot.PageNumber
        }
      }),
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    })
    .then(data => {
      console.log(data);
      // create a FreeText annotation with the sequential_id to the right of the rectangle
      const { Annotations, annotManager } = instance;
      const textAnnot = new Annotations.FreeTextAnnotation();
      textAnnot.PageNumber = annot.PageNumber;
      textAnnot.X = annot.X + annot.Width;
      textAnnot.Y = annot.Y;
      textAnnot.setContents(data.sequential_id.toString());
    
      // Disable auto setting the annotation to be in text editing mode
      const shouldAutoSelect = false;
      annotManager.addAnnotation(textAnnot, shouldAutoSelect);
      annotManager.redrawAnnotation(textAnnot);
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  }
}