import { Controller } from "@hotwired/stimulus"
import Sortable from 'sortablejs'

export default class extends Controller {
  static targets = [ "id", "project_id", "protocol_id" ]

  connect() {
    this.sortable = Sortable.create(this.element, {
      handle: ".handle",
      onEnd: this.end.bind(this),
    })
  }

  end(event) {
    let url = this.element.dataset.dragUrl
    let data = new FormData()
    let id = event.item.dataset.id
    let project_id = event.item.dataset.project_id
    let protocol_id = event.item.dataset.protocol_id
    
    data.append("position", event.newIndex + 1)
    Rails.ajax({
      url: url.replace(":id", id).replace(":project_id", project_id).replace(":protocol_id", protocol_id),
      type: 'PATCH',
      data: data
    })
  }
}