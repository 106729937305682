import { Controller } from "@hotwired/stimulus"
import L from "leaflet"
import {leafletBlueprint} from "../src/blueprint";

export default class extends Controller {

    static values = {
        lat: Number,
        lng: Number,
        issuestate: String,
        issues: Array
    }

    static targets = [
        'mapbox',
        'map'
    ]

    connect() {
        if (this.latValue) {
            this.mapper().then(() => {
                this.placeMarker(
                    {
                    lat: this.latValue,
                    lng: this.lngValue
                    },
                    this.issuestateValue
                )
            })      
        } else {
            this.mapper().then(() => {
                parent = this

                this.issuesValue.forEach( function(issue) {                    
                    parent.placeMarker(                        
                        {
                        lat: issue.lat,
                        lng: issue.lng
                        },
                        issue.issueState
                    )
                })
            })
        }

    }

    // Show only mapper
    async mapper() {
        this.image = this.mapTarget

        this.map = await leafletBlueprint(this.image, this.mapboxTarget, this.mapTarget)


    }

    async placeMarker(latlng, issueState) {
        if (latlng.lng == 0 && latlng.lat == 0) {
            return;
        }

        return this.marker = await L.marker(latlng,  {
            icon: this.markerIcon(issueState),
            draggable: false
        }).addTo(this.map);
    }

    markerIcon(state) {
        return L.divIcon({
            html: this.iconSvg(state),
            className: '',
            iconSize: [32, 32],
            iconAnchor: [16, 32],
        })

    }

    iconSvg(state) {
        if (state === "open") {
            return '<svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10 text-red-800 absolute" viewBox="0 0 20 20" fill="currentColor" id="blueprint_marker">\n' +
                '<path fill-rule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd"/>\n' +
                '</svg>'
        } else if (state === "response_received") {
            return '<svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10 text-orange-800 absolute" viewBox="0 0 20 20" fill="currentColor" id="blueprint_marker">\n' +
                '<path fill-rule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd"/>\n' +
                '</svg>'
        } else if (state === "done") {
            return '<svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10 text-green-800 absolute" viewBox="0 0 20 20" fill="currentColor" id="blueprint_marker">\n' +
                '<path fill-rule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd"/>\n' +
                '</svg>'
        }
    }

    disconnect() {
        if (this.map) {
            this.map.remove()
        }
    }

}