import {Controller} from "@hotwired/stimulus"
import {get} from '@rails/request.js'

export default class extends Controller {
    static targets = ["map", "mapbox"]

    async change(event) {
        let origin_protocol = event.target.selectedOptions[0].value

        get(`/protocols/new?type=followup&origin_protocol_id=${origin_protocol}`, {
            responseKind: "turbo-stream"
        })
    }
}