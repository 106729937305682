import { Controller } from "@hotwired/stimulus"
import ClipboardJS from "clipboard"
import tippy from "tippy.js";

export default class extends Controller {
  static values = {
    successMessage: { type: String, default: "Copied!" },
    errorMessage: { type: String, default: "Failed!" },
    modalId: String
  }

  connect() {
    console.log(this.modalIdValue)
    const containerOption = this.modalIdValue ? { container: document.getElementById(this.modalIdValue) } : {};

    this.clipboard = new ClipboardJS(this.element, {
      text: (trigger) => trigger.getAttribute('data-clipboard-text'),
      ...containerOption
    });

    this.clipboard.on("success", (e) => this.tooltip(this.successMessageValue))
    this.clipboard.on("error",   (e) => this.tooltip(this.errorMessageValue))
  }

  tooltip(message) {
    tippy(this.element, {
      content: message,
      showOnCreate: true,
      appendTo: this.modalIdValue ? document.getElementById(this.modalIdValue) : document.body,
      onHidden: (instance) => {
        instance.destroy()
      }
    })
  }

  get successMessage() {
    return this.successMessageValue || "In Zwischenablage kopiert!"
  }

  get errorMessage() {
    return this.errorMessageValue || "Kopieren fehgeschlagen!"
  }
}
