import {Controller} from "@hotwired/stimulus"
import {FetchRequest} from '@rails/request.js'
import * as L from "leaflet"
import "leaflet-sleep"
import "leaflet.fullscreen"

import {leafletBlueprint} from "../src/blueprint";

export default class extends Controller {
    static values = {
        project: Number,
        persisted: Boolean,
        lat: Number,
        lng: Number
    }

    static targets = ["map", "mapbox"]

    async change(event) {
        let location = event.target.selectedOptions[0].value

        if (location == '') {
            location = 'none'
        }
        let project = this.projectValue

        const request = new FetchRequest('get', `/projects/${project}/locations/${location}/blueprint`, {
            responseKind: "turbo-stream"
        })

        let response = await request.perform()

        if (response.ok) {
            this.turboResponseImage = true
        }
    }

    async mapboxTargetConnected() {
        await this.mapper()

        if (!this.turboResponseImage) {
            let that = this

            if (this.latValue !== 0 && this.lngValue !== 0) {

                await this.placeMarker(L.latLng(this.latValue, this.lngValue)).then(() => {
                    if (that.marker) {
                        that.marker.on('dragend', () => {
                            this.setLatLngPixXYValues({
                                layerPoint: that.map.latLngToLayerPoint(that.marker._latlng),
                                latlng: that.marker._latlng
                            })
                        })
                    }
                })
            }
        }
    }

    async mapper() {
        this.image = this.mapTarget
        this.map = await leafletBlueprint(this.image, this.mapboxTarget, this.mapTarget)

        // Map shaking workaround
        const previousZoom = this.map.getZoom()
        this.map.setZoom(previousZoom + 0.01)
        this.map.setZoom(previousZoom - 0.01)

        let that = this;

        this.map.on('click', function (event) {
            if (that.marker !== undefined) {
                that.map.removeLayer(that.marker)
            }

            that.placeMarker(event.latlng).then(() => {
                that.setLatLngPixXYValues(event)
            })

        })

        L.Control.removeMarker = L.Control.extend({
            onAdd: function (map) {
                let container = L.DomUtil.create('div')
                L.DomUtil.addClass(container, 'leaflet-control leaflet-bar');

                let button = '<a href="#" class="justify-center items-center flex" ><svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">\n' +
                    '  <path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />\n' +
                    '</svg></a>'

                container.addEventListener('click', (e) => {
                    this.fire('removeMarkerEvent');
                    L.DomEvent.stop(e)
                });

                container.innerHTML = button

                L.DomEvent.disableClickPropagation(container);

                return container;
            }
        });
        L.extend(L.Control.removeMarker.prototype, L.Evented.prototype);

        that = this;
        let markerControl = new L.Control.removeMarker({position: 'topleft'}).addTo(this.map);
        markerControl.on('removeMarkerEvent', function () {
            if (that.marker && that.map) {
                that.map.removeLayer(that.marker);

                that.clearLatLngPixXYValues();
            }
        })

    }

    async placeMarker(latlng) {
        if (latlng.lng == 0 && latlng.lat == 0) {
            return;
        }
        return this.marker = await L.marker(latlng, {
            icon: L.divIcon({
                html: '<svg xmlns="http://www.w3.org/2000/svg" class="" viewBox="0 0 20 20" fill="currentColor">\n' +
                    '  <path fill-rule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd" />\n' +
                    '</svg>',
                className: '',
                iconSize: [40, 40],
                iconAnchor: [20, 40],
            }),
            draggable: false
        }).addTo(this.map);
    }

    setLatLngPixXYValues(event) {
        // Set pixel X/Y
        let positionRelativeToImageCoordinates = this.convertPosToImgCoordinates(event.layerPoint, event.latlng)

        if (positionRelativeToImageCoordinates !== null) {
            document.getElementById('issue_marker_x_pixel').value = positionRelativeToImageCoordinates.x
            document.getElementById('issue_marker_y_pixel').value = positionRelativeToImageCoordinates.y
        } else {
            // Marker outside the image
            // TODO: Implement how to handle marker outside image
        }

        // Set Marker Lat/Lng
        document.getElementById('issue_marker_lat').value = event.latlng.lat
        document.getElementById('issue_marker_lng').value = event.latlng.lng
    }

    clearLatLngPixXYValues() {
        document.getElementById('issue_marker_x_pixel').value = null
        document.getElementById('issue_marker_y_pixel').value = null
        document.getElementById('issue_marker_lat').value = null
        document.getElementById('issue_marker_lng').value = null
    }

    convertPosToImgCoordinates(pos, latlng) {
        let width = this.image.naturalWidth, height = this.image.naturalHeight;

        let realX = latlng.lng / width;
        let realY = 1 - latlng.lat / height;

        let x, y;
        if (realX >= 0 && realX <= 1 && realY >= 0 && realY <= 1) {
            x = realX, y = realY
        } else {
            return null;
        }

        return {
            x: Math.round(x * width),
            y: Math.round(y * height)
        }

    }

    disconnect() {
        if (this.map) {
            this.map.remove()
        }
    }

}