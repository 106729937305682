import { Controller } from "@hotwired/stimulus"
import isMobile from 'ismobilejs';

export default class extends Controller {
    initialize() {
        this.isMobile = isMobile(window.navigator).phone;
    }

    connect() {
        //console.log("xxx", this.isMobile);

        // Prevent hooking function when on Mobile
        if (!this.isMobile) {
            this.listener = this.element.addEventListener('keydown', (event) => { this.formSubmit(event) });
        }
    }

    formSubmit(event) {
        if (!(event.keyCode === 13 && event.metaKey)) return;

        this.element.requestSubmit();
    }

    disconnect() {
        this.element.removeEventListener('keydown', (event) => { this.formSubmit(event) });
    }
}