import { Controller } from "@hotwired/stimulus"
import Uppy from '@uppy/core'
import Dashboard from '@uppy/dashboard'
import DragDrop from '@uppy/drag-drop';
import Webcam from '@uppy/webcam';
import German from '@uppy/locales/lib/de_DE';
import Audio from '@uppy/audio';
import GoldenRetriever from '@uppy/golden-retriever';
import Form from '@uppy/form';


import ActiveStorageUpload from '@excid3/uppy-activestorage-upload'

// Example HTML for Uppy with ActiveStorage
//
// <div data-controller="uppy" data-uppy-field-name-value="photo[image]">
//   <img data-uppy-target="preview" src="https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSpj0DBTVsaja01_xWh37bcutvpd7rh7zEd528HD0d_l6A73osY" width="280" data-behavior="uppy-preview" />
//   <button type="button" data-uppy-target="trigger">Upload</button>
// </div>

export default class extends Controller {
  // Adds Uppy loads for a form field
  // If a preview target (img tag) is available, sets the src to the uploaded image
  // Adds or updates the field target in the form

static targets = ["trigger", "preview", "previews", "field", "dashboard", "form"]
  static values = {
    fieldName: { type: String, default: "uppy-image" }
  }

  connect() {
    this.uppy = new Uppy({
      autoProceed: true,
      allowMultipleUploadBatches: true,
      locale: German,
      logger: this.debugLogger
    })

    this.uppy.use(ActiveStorageUpload, {directUploadUrl: this.directUploadUrl}) 
    this.uppy.use(Dashboard, {
      target: '#file-uppy',
      showProgressDetails: true,
      hideUploadButton: true,
      inline: true,
      proudlyDisplayPoweredByUppy: false,
      height: 210,
      showRemoveButtonAfterComplete: true,
      hideProgressAfterFinish: true,
      showSelectedFiles: true,
      singleFileFullScreen: false
    })

    // this.uppy.use(DragDrop, { target: '#drag-drop' });


    //this.uppy.use(Webcam, { target: Dashboard, mobileNativeCamera: true });
    //this.uppy.use(Audio, { target: Dashboard });
    //this.uppy.use(GoldenRetriever);
    this.uppy.on('complete', this.complete.bind(this))

    // Get a reference to the form and the submit button
    let form = document.querySelector('#issue_form') || document.querySelector('#reply_form');
    const submitButton = form.querySelector('button[type="submit"]');

    // Disable the submit button when an upload starts
    this.uppy.on('upload', () => {
      submitButton.disabled = true;
    });

    // Enable the submit button when all uploads are complete
    this.uppy.on('complete', (result) => {
      const files = this.uppy.getState().files;
      if (Object.keys(files).every(key => files[key].progress.uploadComplete)) {
        submitButton.disabled = false;
      }
    });

    // Also enable the submit button if an error occurs
    this.uppy.on('error', () => {
      submitButton.disabled = false;
    });
  }

  disconnect() {
    this.uppy.close()    
  }

  complete(result) {
    result.successful.forEach(file => {
      this.appendFileField(file)
      this.setPreview(file)
      console.log("setpreview")
    })

    //this.uppy.cancelAll();
  }

  appendFileField(file) {
    // If we already have a field target, update it's value
    // This prevents submitting multiple values for the same field
    if (false) { // this.hasFieldTarget
      console.log("hasFieldTarget")
      this.fieldTarget.setAttribute("value", file.response.signed_id)
    } else {
      console.log("hasNOTFieldTarget")
      console.log(this.fieldNameValue)
      const field = document.createElement("input")
      field.setAttribute("type", "hidden")
      field.setAttribute("data-uppy-target", "field")
      field.setAttribute("name", this.fieldNameValue)
      field.setAttribute("data-pending-upload", true)
      field.setAttribute("value", file.response.signed_id)
      this.element.appendChild(field)
    }
  }

  setPreview(file) {
    if (this.hasPreviewsTarget) {
      let src = (file.preview) ? file.preview : "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSpj0DBTVsaja01_xWh37bcutvpd7rh7zEd528HD0d_l6A73osY"
      //this.previewTarget.src = src
      var element = document.createElement("img");
      element.src = src
      const imagePreview = this.previewsTarget.appendChild(element);
    }
  }

  get debugLogger() {
    return {
      debug: (...args) => console.debug(`[Uppy]`, ...args),
      warn: (...args) => console.warn(`[Uppy]`, ...args),
      error: (...args) => console.error(`[Uppy]`, ...args),
    }
  }

  get directUploadUrl() {
    return document.querySelector("meta[name='direct-upload-url']").getAttribute("content")
  }
}